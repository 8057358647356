export default {
    "textToast": {
        "sendQueryText": "Response sent"
    },
    "form": {
        "send": "Send",
        "title": "Queries",
        "hello": "Hello",
        "whatsup": "What's up",
        "thanksAll" : "Thank you for everything",
        "btn-wrong-stay": "Very bad",
        "btn-normal-stay": "Normal",
        "btn-good-stay": "Very good",
        "btn-wrong-poststay": "Very bad",
        "btn-normal-poststay": "Normal",
        "btn-good-poststay": "Very good",
        "poststay-bad-thanks-title": "Reply sent", 
        "poststay-bad-thanks-subtitle": "Thank you very much for your time",
    },
    "settings": {
        "questionpre-stay": "Is there anything we can do for your arrival at the hotel?",
        "questionin-stay": "How would you rate your level of satisfaction with your stay so far?",
        "questionpost-stay": "How was your experience with us?",
    },
}
