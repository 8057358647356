export default {
    "textToast": {
        "sendQueryText": "Risposta inviata"
    },
    "form": {
        "send": "Invia",
        "title": "Domande",
        "hello": "Ciao",
        "whatsup": "Come va",
        "thanksAll" : "Grazie di tutto",
        "btn-wrong-stay": "Molto male",
        "btn-normal-stay": "Normale",
        "btn-good-stay": "Molto bene",
        "btn-wrong-poststay": "Molto male",
        "btn-normal-poststay": "Normale",
        "btn-good-poststay": "Molto bene",
        "poststay-bad-thanks-title": "Risposta inviata", 
        "poststay-bad-thanks-subtitle": "Grazie mille per il tuo tempo",
    },
    "settings": {
        "questionpre-stay": "C'è qualcosa che possiamo fare per il tuo arrivo in hotel?",
        "questionin-stay": "Come valuteresti il tuo livello di soddisfazione finora?",
        "questionpost-stay": "Com'è stata la tua esperienza con noi?",
    },
}
