export default {
    "textToast": {
        "sendQueryText": "Réponse envoyée"
    },
    "form": {
        "send": "Envoyer",
        "title": "Demandes",
        "hello": "Bonjour",
        "whatsup": "Comment ça va",
        "thanksAll" : "Merci pour tout",
        "btn-wrong-stay": "Très mauvais",
        "btn-normal-stay": "Normal",
        "btn-good-stay": "Très bien",
        "btn-wrong-poststay": "Très mauvais",
         "btn-normal-poststay": "Normal",
         "btn-good-poststay": "Très bien",
         "poststay-bad-thanks-title": "Réponse envoyée", 
         "poststay-bad-thanks-subtitle": "Merci beaucoup pour votre temps",
    },
    "settings": {
        "questionpre-stay": "Y a-t-il quelque chose que nous pouvons faire pour votre arrivée à l'hôtel?",
        "questionin-stay": "Comment évalueriez-vous votre niveau de satisfaction avec votre séjour jusqu'à présent?",
        "questionpost-stay": "Comment a été votre expérience avec nous?",
    },
}
